import { LoginType } from 'react-aad-msal';
import * as MSAL from 'react-aad-msal';

import { NodeDefiner } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderStatus,
  DeathCaseUpdateMutationInput,
  Permissions,
  UserAccountType,
  UserVerificationStatus,
} from '@Graphql/graphqlTypes.generated';
import { StatusIndicators } from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel';

import { Translator } from './types';

export const noop = () => {};
export const isFunction = (value: any) => typeof value === 'function';
// eslint-disable-next-line no-console
export const logError = (...values: any[]) => console.error(...values);
// eslint-disable-next-line no-console
export const logInfo = (...values: any[]) => console.info(...values);

export const capitalize = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const downloadFile = (filePath: string) => {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
};

export const isYotiStatusPending = (status: UserVerificationStatus | undefined) =>
  status === UserVerificationStatus.NeedsReview || status === UserVerificationStatus.Verifying;

export const isYotiStatusNotVerified = (status: UserVerificationStatus | undefined) =>
  status === UserVerificationStatus.NotVerified;

export const domainName = window.location.hostname === 'localhost' ? 'http://localhost:8000' : '';

export const apiDomain = () => {
  const webDomain = window.location.hostname;
  if (webDomain === 'app.dev.lifeledger.com') {
    return 'https://dev.api.lifeledger.com/';
  }
  if (webDomain === 'app.lifeledger.com') {
    return 'https://api.lifeledger.com/';
  }
  return 'http://localhost:8000/';
};

export const chooseIcon = (missingItem: string) => {
  if (missingItem.includes('files')) {
    return 'file';
  }
  if (missingItem.includes('user_verification')) {
    return 'lock';
  }
  if (missingItem.includes('address')) {
    return 'home';
  }
  return 'info';
};

export const addressHelper = (detail?: string) => {
  return detail || '';
};

export const decideStatus = (statusType: CaseServiceProviderStatus): StatusIndicators => {
  let icon = '';
  let statusHeader = '';
  let statusTitle = '';
  let statusDescription = '';
  let backgroundColor = '';
  let statusTitleColor = '';

  switch (statusType) {
    case CaseServiceProviderStatus.InProgress:
    case CaseServiceProviderStatus.InProgressAutoPopulationFailed:
      icon = 'clock';
      statusHeader = 'The status of this death notification is ';
      statusTitle = 'In progress';
      statusDescription =
        'Please change the status of this notification once you have solved the request or if this death has already been processed';
      backgroundColor = 'inProgessBackgroundColor';
      statusTitleColor = 'yellowishOrange';
      break;
    case CaseServiceProviderStatus.NoActionRequired:
      icon = 'checkmark';
      statusHeader = 'No further Action Required: ';
      statusTitle = 'In progress outside Life Ledger';
      statusDescription = 'You are managing the request and are in contact with the notifier outside of Life Ledger';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.CompletedClosed:
      icon = 'doneAll';
      statusHeader = 'Complete:';
      statusTitle = 'The account has been closed ';
      statusDescription = 'You have completed the request made by the notifier, and the account has been closed';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.CompletedSwitched:
      icon = 'shuffle';
      statusHeader = 'Complete:';
      statusTitle = 'The account has been switched ';
      statusDescription = 'You have completed the request made by the notifier, and the account has been switched';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.AlreadyRegistered:
      icon = 'doneAll';
      statusHeader = 'No further Action Required: ';
      statusTitle = 'This death has already been registered';
      statusDescription = 'This case was dealt with outside of Life Ledger';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.NotFound:
      icon = 'person-delete';
      statusHeader = 'No further Action Required: ';
      statusTitle = 'No account found';
      statusDescription = 'Your given details could not be matched with with an account';
      backgroundColor = 'statusErrorColor';
      statusTitleColor = 'red';
      break;
    case CaseServiceProviderStatus.Sent:
      icon = 'more-horizontal';
      statusHeader = 'The status of this death notification is ';
      statusTitle = 'Unopened';
      statusDescription = 'Notification is sent to the service provider';
      backgroundColor = 'sentStatusColor';
      statusTitleColor = 'black';
      break;
    case CaseServiceProviderStatus.Confirmed:
      icon = 'doneAll';
      statusHeader = 'Confirmed:';
      statusTitle = 'Confirmed';
      statusDescription = 'You have completed the request made by the notifier, and the account has been closed';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.CompletedByLl:
      icon = 'doneAll';
      statusHeader = 'Complete:';
      statusTitle = Messages.statusCompletedByLl;
      statusDescription = Messages.statusDescCompletedByLl;
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.Closed:
      icon = 'doneAll';
      statusHeader = 'Closed:';
      statusTitle = 'title_notification_closed';
      statusDescription = 'desc_notification_closed';
      backgroundColor = 'statusSuccessColor';
      statusTitleColor = 'profRegisterBackground';
      break;
    case CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier:
      icon = 'clock';
      statusHeader = 'The status of this death notification is: ';
      statusTitle = 'Further action needed';
      statusDescription =
        'The company has started working on your request and has identified that you need to provide more information before the notification can be fully processed. Provide this information in the messages and documents tab.';
      backgroundColor = 'inProgessBackgroundColor';
      statusTitleColor = 'yellowishOrange';
      break;
    case CaseServiceProviderStatus.InProgressAwaitingInformationFromSp:
      icon = 'clock';
      statusHeader = 'The status of this death notification is: ';
      statusTitle = 'in progress';
      statusDescription =
        "The company has started working on your request, and they will contact you if they need more information. You don't need to do anything else at the moment.";
      backgroundColor = 'inProgessBackgroundColor';
      statusTitleColor = 'yellowishOrange';
      break;
    default:
      break;
  }
  const statusDetails: StatusIndicators = {
    icon,
    statusHeader,
    statusTitle,
    statusDescription,
    backgroundColor,
    statusTitleColor,
  };
  return statusDetails;
};

export const plotMessageUserName = (chat: any) => {
  const splitUserName = chat.commentedByName.split(' ');
  return splitUserName.length > 1
    ? `${splitUserName[0].charAt(0).toUpperCase()}${splitUserName[1].charAt(0).toUpperCase()}`
    : `${splitUserName[0].charAt(0).toUpperCase()}`;
};

export const modifyMissingItems = (missingItems: any): string[] => {
  const missingInfo = missingItems.map((x: any) => {
    if (x.field === 'address_1' || x.field === 'postal_code' || x.field === 'city') {
      return 'address';
    }
    return x.field;
  });
  const unique = missingInfo.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  return unique;
};

export const disableNextButton = (values: any, isValid: any, assignedAddress: number) => {
  if (
    !isValid ||
    (assignedAddress === -1 && values.address1 === '' && values.city === '' && values.postalCode === '')
  ) {
    return true;
  }
  return false;
};

export const manipulateTranslations = (t: Translator, labelKey: string, labelValue: string) => {
  const translated = t(labelKey as Messages);
  return translated === labelKey ? labelValue : translated;
};

export const removeLeadingZeroAndSpaceInContact = (phone: string) => {
  let afterSpaceRemoval = phone.replace(/ +/g, '');
  if (afterSpaceRemoval.startsWith('+44')) {
    const charArray = [];
    for (let i = 0; i < afterSpaceRemoval.length; i += 1) {
      if (i === 3 && afterSpaceRemoval[i] === '0') {
        charArray.push('');
      } else {
        charArray.push(afterSpaceRemoval[i]);
      }
    }
    afterSpaceRemoval = charArray.join('');
  }
  return afterSpaceRemoval;
};

export interface LooseObject {
  [key: string]: any;
}

export const getReadyOnlyPermissions = (caseData: any) => {
  if (caseData?.__typename === NodeDefiner.DeathCase || caseData?.__typename === NodeDefiner.LifeCase) {
    return caseData.myPermission === Permissions.ReadOnly;
  }
  return false;
};

export const getAttributeValue = (attribute: string) => {
  return document.getElementById('root')?.getAttribute(attribute) || '';
};

export const msalConfig = {
  auth: {
    clientId: '44a4c1fe-95ae-4a41-9c98-416cd18733ac', // Replace with your Application (client) ID
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${window.location.origin}/ms-landing`, // Replace with your redirect URI
    postLogoutRedirectUri: `${window.location.origin}/logout`,
  },
  cache: {
    //cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const authenticationParameters = {
  scopes: ['openid', 'profile', 'User.Read', 'api://44a4c1fe-95ae-4a41-9c98-416cd18733ac/scope'],
};

export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/ms-landing`,
};

export const msalInstance = new MSAL.MsalAuthProvider(msalConfig, authenticationParameters, options);

export const chooseAccountType = (actType: string) => {
  if (actType === UserAccountType.Professional) {
    return UserAccountType.Professional;
  }
  if (actType === UserAccountType.Agent) {
    return UserAccountType.Agent;
  }
  return UserAccountType.Individual;
};

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}

export const convertStatus = (statusInString: string): CaseServiceProviderStatus => {
  let originalStatus = CaseServiceProviderStatus.InProgress;
  switch (statusInString) {
    case 'IN_PROGRESS':
      originalStatus = CaseServiceProviderStatus.InProgress;
      break;
    case 'NO_ACTION_REQUIRED':
      originalStatus = CaseServiceProviderStatus.NoActionRequired;
      break;
    case 'COMPLETED_CLOSED':
      originalStatus = CaseServiceProviderStatus.CompletedClosed;
      break;
    case 'COMPLETED_SWITCHED':
      originalStatus = CaseServiceProviderStatus.CompletedSwitched;
      break;
    case 'ALREADY_REGISTERED':
      originalStatus = CaseServiceProviderStatus.AlreadyRegistered;
      break;
    case 'NOT_FOUND':
      originalStatus = CaseServiceProviderStatus.NotFound;
      break;
    case 'SENT':
      originalStatus = CaseServiceProviderStatus.Sent;
      break;
    case 'READY_TO_SEND':
      originalStatus = CaseServiceProviderStatus.ReadyToSend;
      break;
    case 'CONFIRMED':
      originalStatus = CaseServiceProviderStatus.Confirmed;
      break;
    case 'COMPLETED_BY_LL':
      originalStatus = CaseServiceProviderStatus.CompletedByLl;
      break;
    case 'CLOSED':
      originalStatus = CaseServiceProviderStatus.Closed;
      break;
    case 'IN_PROGRESS_AWAITING_INFORMATION_FROM_NOTIFIER':
      originalStatus = CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier;
      break;
    case 'IN_PROGRESS_AWAITING_INFORMATION_FROM_SP':
      originalStatus = CaseServiceProviderStatus.InProgressAwaitingInformationFromSp;
      break;
    case 'IN_PROGRESS_AUTO_POPULATION_FAILED':
      originalStatus = CaseServiceProviderStatus.InProgressAutoPopulationFailed;
      break;
    default:
      break;
  }
  return originalStatus;
};

export const topSection = (user: any, isRes875: boolean) => {
  if (user?.businessReferral) {
    return isRes875 ? 12 : 11;
  }
  return 12;
};

type KeysOfType<T> = (keyof T)[];

export const deathCaseUpdateMutationInputKeys: KeysOfType<DeathCaseUpdateMutationInput> = [
  'relationship',
  'isUserExecutor',
  'isUserPartner',
  'hasPartner',
  'shouldNotifyPartner',
  'partnersFirstName',
  'partnersLastName',
  'partnersEmail',
  'partnersPhone',
  'isAlsoAdministrator',
  'isAlsoLegal',
  'isAlsoOtherAdvisor',
  'isAlsoExecutor',
  'isSmartModalUpdate',
  'title',
  'titleOther',
  'firstName',
  'middleName',
  'lastName',
  'knownName',
  'birthDate',
  'buildingNumber',
  'buildingName',
  'address1',
  'address2',
  'city',
  'postalCode',
  'propertyType',
  'occupancyStatus',
  'prevBuildingNumber',
  'prevBuildingName',
  'previousAddress1',
  'previousAddress2',
  'previousCity',
  'previousPostalCode',
  'deathDate',
  'deathRegisteredDate',
  'funeralDate',
  'representationLetterDate',
  'deathCertificateNo',
  'actingCapacity',
  'id',
  'shouldPassPartnersUtility',
  'hasWill',
  'isAddressOverThree',
  'hasAuthority',
  'consent',
  'clientMutationId',
] as KeysOfType<DeathCaseUpdateMutationInput>;

export const vulnerabilityVisibility = (deathCase: any) => {
  if (
    deathCase.notifier.declaredVulnerability ||
    deathCase.notifier.vulnerabilityNature?.length > 0 ||
    deathCase.notifier.vulnerabilityOtherDetails
  ) {
    return true;
  }
  return false;
};
